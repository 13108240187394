import { SessionStorage } from "../utils/SessionStorage"
import moment                                 from "moment";
import 'moment/locale/es';
import _                                      from 'lodash';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Space, Button, Card, Select, Popconfirm, Typography,message,Alert } from 'antd';
import { ServicesData } from "../services/ServicesData";

moment.locale('es');//configurar moment en español

export const Administracion = () => {

    
    const [mesSelected, setMesSelected] = useState();
    const [meses, setMeses] = useState([]);
    const [pendientes, setPendientes] = useState([]);
    const [modalCerrar, setModalCerrar] = useState(false);
    const [procesando, setProcesando] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        document.title = "Regantes - Administración";
        getMeses();
        ServicesData.getPendientes().then(result => setPendientes(result));
      }, []);

    const getMeses = async () => {
        // Crear la fecha inicial y restar un mes
        const startDate = moment(`${SessionStorage.getItem('user_info').ejercicio}${SessionStorage.getItem('user_info').mes}`, 'YYYYMM').subtract(1, 'month');
        const nuevosMeses = [];
        for (let i = 0; i < 12; i++) {
            const date = startDate.clone().subtract(i, 'months');
            if (date>=moment('202401', 'YYYYMM')){
                nuevosMeses.push({
                    label: date.format('MMMM YYYY').toUpperCase(),
                    value: date.format('YYYYMM')
                  });
      
            }
          }
        setMeses(nuevosMeses);
        setMesSelected(nuevosMeses[0])
    }

    const selectedMes = async(value) => {
        setMesSelected(value);
    }

    const getFichero = () => {
        const dato = moment(mesSelected?.value, 'YYYYMM');
        return process.env.REACT_APP_API_URL+"data?mes="+(dato.month()+1)+"&ejercicio="+dato.year();
    }



    return (
        <>
        {contextHolder}
        <Card>
            <Space direction="vertical" style={{width:'100%'}}>
                <Typography.Title level={3}>
                Mes activo: {moment().month(SessionStorage.getItem('user_info').mes-1).format("MMMM").toUpperCase()+' '+SessionStorage.getItem('user_info').ejercicio}
                </Typography.Title>
                <div style={{marginTop:'10px'}}>
                    <Typography.Text>
                        Descarga de datos para el mes:
                    </Typography.Text>
                    <Select
                        popupMatchSelectWidth={200}
                        placeholder="Selecciona un mes"
                        options={meses}
                        value={mesSelected}
                        labelInValue={true}
                        onSelect={selectedMes}
                        variant="borderless"
                    />
                    <Typography.Link href={getFichero()} target="_blank">
                        Descargar
                    </Typography.Link>
 
                </div>
                    <div style={{marginTop:'10px'}}>
                        <Popconfirm title={"¿Está seguro de que quiere cerrar el mes " +
                                            moment().month(SessionStorage.getItem('user_info').mes-1).format("MMMM").toUpperCase() +
                                            " " + SessionStorage.getItem('user_info').ejercicio + "?"}
                            onConfirm={() => {
                                setProcesando(true);
                                ServicesData.closeMes()
                                .then((response) => {
                                    if (response==false){
                                        messageApi.open({
                                            type: 'error',
                                            content: 'No se puede cerrar el mes. Contacte con administrador',
                                        })
                                        setProcesando(false);
                                    }else{
                                        let userinfo  = SessionStorage.getItem('user_info');
                                        userinfo.mes = response.mes;
                                        userinfo.ejercicio = response.ejercicio;
                                        SessionStorage.setItem('user_info',userinfo);
                                        getMeses();
                                        setProcesando(false);    
                                    }
                                  });
                            }}
                            onCancel={() => {}}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button icon={<CloseOutlined />} loading={procesando} disabled={pendientes.length>0}> Cerrar mes activo</Button>
                        </Popconfirm>
                        {pendientes.length>0 &&
                            <>  
                                <br/><br/>
                                <Alert  type="error"
                                        message="Tiene contadores pendientes de notificar"
                                        description={pendientes.join(", ")}  />

                            </>
                        }
                    </div>
            </Space>
        </Card>
        </>
    )
}